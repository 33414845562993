<template>
  <div>
    <div class="ynm">
      <app-button v-on:click.native="response(95)" class="col-lg-12 col-md-12 col-sm-12 btn-ynm" style="background: #57ad26" variation="primary">{{$t('op1')}}</app-button>
      <app-button v-on:click.native="response(5)" class="col-lg-12 col-md-12 col-sm-12 btn-ynm" style="background: #ea484d" variation="secondary">{{$t('op2')}}</app-button>
      <app-button v-on:click.native="response(75)" class="col-lg-12 col-md-12 col-sm-12 maybe btn-ynm">{{$t('op4')}}</app-button>
    </div>
  </div>
</template>

<script>
import { translator } from '@/_helpers'

export default {
  name: 'appynm',
  props: ['question', 'colors'],
  components: {
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button')
  },
  mounted () {
    this.$root.$on('footer-submit', payload => {
      if (payload) {
        this.response('skip')
      }
    })
  },
  created () {
    if (this.question.language) {
      this.$i18n.locale = translator.getCurrentLocale(this.question.language)
    }
  },
  methods: {
    async response (value) {
      let data = {}
      value === 'skip' ? data = { skip: true } : data = { rating: value }
      this.$emit('value-changed', data)
      this.$emit('question-answered', true)
    }
  }
}
</script>
<style lang="scss">
.ynm {
  height: 370px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .btn-ynm {
    font-size: 19px;
    margin: 20px 0;
    width: 230px;
    height: 45px;
    border-radius: 35px !important;
    box-shadow: 0 5px 15px 0 rgb(215, 215, 215) !important;
  }
  .maybe {
    background: #f8c43d !important;
    color: #ffffff !important;
  }
}

// responsivity to mobile
@media only screen and (max-width: 850px) {
  .ynm {
    height: 100%;
  }
}
</style>
